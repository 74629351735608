import React, { useEffect, useState } from 'react';
import '../../assets/styles/carousel.css';

function importAll(r) {
    return r.keys().map(r);
}

const Carousel = ({ scrollDirection }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const importedImages = importAll(require.context('../../assets/images/titsrp', false, /\.(png|jpe?g|svg|gif)$/));
        importedImages.sort(() => Math.random() - 0.5);
        if (scrollDirection === 'left') {
            importedImages.reverse();
        }
        setImages(importedImages);
    }, []);

    return (
        <div className="carousel-container">
            <div className={"carousel-" + scrollDirection}>
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index + 1}`} />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
